import axios from 'axios'

export default {
    namespaced: true,
    state: {
        student: {}
    },
    getters: {
        getStudent(state){
            return state.student
        }
    },
    mutations: {
        setStudent(state, value){
            state.student = value
        }
    },
    actions: {
        postStudent({ getters }){
            const url = '/api/student';
            return axios.post(url, getters.getStudent);
        },
        deleteStudent({ getters }){
            const url = '/api/student';
            console.log(getters.getStudent);
            return axios.delete(url, { data: getters.getStudent });
        },
        getStudentStatus(){
            const url = '/api/student-status';
            return axios.get(url);
        },

        getCurrentStudents(){
            let url = '/api/students';
            return axios.get(url);
        },
    }
}    