<template>
    <v-row
        justify="center"
    >
        
        <v-col>        
            <v-card
                class="mx-auto pa-0"
                width="1000"
            >
                <v-card-title
                    class="d-flex primary--text py-2 px-0 "
                >
                    <v-spacer></v-spacer>
                    <div class="caption font-weight-bold primary--text text-uppercase">Mark Sheet</div>
                    <v-spacer></v-spacer>
                    <v-btn
                        text                
                        x-small
                        color="primary"
                        @click="closeMarkSheet"
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>            
                </v-card-title>
                
                <v-row class="px-4">
                    <v-col class="py-0">
                        <Header/>
                        <v-divider></v-divider>
                        <MarkTable/>
                        <v-row class="mt-3 mr-4">
                            <v-spacer></v-spacer>
                            <div class="caption">
                                Total Students: {{ totalStudents }}
                            </div>
                        </v-row>
                    </v-col>
                </v-row>
                
                <v-overlay
                    :absolute="absolute"
                    :value="markSheetOverlay"                    
                    color="grey lighten-5"
                    opacity="0.9"
                    class="pa-5"
                >                    
                    <lesson
                        v-if="lessons"
                        v-on:stop-loading="stopLoading"
                        v-on:display-teachers="displayTeachers"
                        v-on:display-loading="displayLoading"
                    ></lesson>

                    <lesson-admin
                        v-if="teachers"
                        v-on:stop-loading="stopLoading"
                        v-on:display-lessons="displayLessons"
                    ></lesson-admin> 
                        
                    <v-progress-circular 
                        transition="fade-transition" 
                        indeterminate 
                        size="64"
                        v-show="loading"
                        color="blue"
                    ></v-progress-circular> 
                </v-overlay>
               
            </v-card>
        </v-col>
        
    </v-row>    
</template>

<script>
import Header from './MarkSheetHeader'
import Lesson from './MarkSheetLesson'
import LessonAdmin from './MarkSheetLessonAdmin.vue'
import MarkTable from './MarkSheetTable'
import { mapGetters, mapMutations } from 'vuex'
export default {
    name: 'MarkSheet',
    created(){
        this.initialize();
    },

    components: {
        Header,
        Lesson,
        LessonAdmin,
        MarkTable,
    },
    computed: {
        ...mapGetters({            
            markSheetOverlay: 'markSheet/getMarkSheetOverlay',
            markSheetLoading: 'markSheet/getMarkSheetLoading',
            totalStudents: 'markSheet/getTotalStudents',  
            admin: 'auth/getAdmin',
        }),
        
      
       
    },
    data: () => ({
        absolute: true,             
        userTimetable: [],
        lessons: false,
        teachers: false,
        loading: false,
    }),
    methods: {
        ...mapMutations({
            mainMenu: 'dashboard/setMainMenu',           
            setOverlay: 'markSheet/setMarkSheetOverlay',
            expand: 'markSheet/setExpandLessons',
            welcomeMessage: 'dashboard/setWelcomeMessage',
            
        }),

        initialize () {
            this.setOverlay(true);
            this.loading = true;
            console.log(`Admin: ${this.admin}`)
            if(!this.admin){
                this.lessons = true;
            }
            else{
                this.teachers = true;
            }           
        }, 

        closeMarkSheet(){
            this.setOverlay(true);
            // this.setMarkSheetLoading(true);           
            this.expand(false);
            
        },

         displayLoading () {
            this.loading = true;
        },

        stopLoading () {
            this.loading = false;
        },

        displayTeachers () {
            this.loading = true;
            this.teachers = true;
            this.lessons = false;
        },

        displayLessons () {
            console.log('display lessons');
            this.loading = true;
            this.teachers = false;
            this.lessons = true;
        }
    }
}
</script>