<template>
    <v-row>         
        <v-col>
            <v-card
                class="pa-0 mx-auto"
                width="400"
                
            >
                    <v-card-title
                        class="text-subtitle-2 pa-2 white--text d-flex justify-center primary"
                    >
                        <v-spacer></v-spacer>
                        <span>Print / View Reports</span>
                        <v-spacer></v-spacer>                   
                    </v-card-title>
                    <v-card-text
                        style="height: 60vh; overflow-y: auto;"
                    >
                        <v-list two-line>
                            <v-list-item-group
                                active-class="primary--text"
                            >
                                <template v-for="(item, index) in items">                                   
                                    <v-list-item 
                                        :key="item.title"
                                        @click="displayReport(item.report)"
                                    >
                                       <template v-slot:default="{ active }">
                                           <v-list-item-content>
                                               <v-list-item-title>
                                                {{ item.title }}
                                               </v-list-item-title>
                                               <v-list-item-subtitle
                                                    class="text--primary"
                                               >
                                                {{ item.subtitle }}
                                               </v-list-item-subtitle>
                                           </v-list-item-content>
                                           <v-list-item-action>
                                               <v-icon
                                                    v-if="!active"
                                                    color="grey lighten-1"
                                               >
                                                   mdi-printer-search
                                               </v-icon>
                                               <v-icon
                                                    v-else
                                                    color="primary"
                                               >
                                                   mdi-printer-search
                                               </v-icon>
                                           </v-list-item-action>
                                       </template>
                                   </v-list-item>

                                   <v-divider
                                        v-if="index < items.length -1"
                                        :key="index"
                                   ></v-divider>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </v-card-text>
                    <v-overlay
                        absolute
                        :value="overlay"
                        color="grey"
                        opacity="0.6"
                   >
                         <v-progress-circular 
                            transition="fade-transition" 
                            indeterminate 
                            size="64"
                            v-show="loading"
                            color="primary"
                        ></v-progress-circular>
                        <v-expand-transition>                            
                            <v-card
                                max-width="300"
                                class="mx-auto"
                                transition="scroll-y-transition"
                                v-show="expand"
                                light
                            >
                                 
                                <v-card-title
                                    class="d-flex justify-center primary white--text"
                                >
                                    <span>Select Class</span>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text                
                                        x-small
                                        color="white"
                                        @click="close"
                                    >
                                        <v-icon>mdi-window-close</v-icon>
                                    </v-btn> 
                                </v-card-title>
                                <v-list 
                                    light
                                    v-if="displayAcademicPeriod"
                                >
                                    <v-list-item>                                        
                                        <v-autocomplete
                                            v-model="academicPeriod"
                                            :items="academicPeriods"
                                            item-text="text"
                                            item-value="value"                                                
                                            dense
                                            filled
                                            color="blue-grey lighten-2"
                                            prepend-icon="mdi-calendar"
                                            hide-details="auto"
                                        ></v-autocomplete>                                        
                                    </v-list-item>
                                </v-list>
                                <div style="max-height: 400px; width: 250px; overflow-y: auto">    
                                    <v-list 
                                        v-if="listGroup"
                                        light 
                                    >
                                        <v-list-group
                                            v-for="item in formClassesFilter"
                                            :key = "item.title"
                                            v-model="item.active"
                                            :prepend-icon="item.action"
                                            no-action
                                        >
                                            <template v-slot:activator>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{ item.title }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                            <v-list-item
                                                v-for="subItem in item.items"
                                                :key="subItem.title"
                                                @click="setSelectedClass(subItem.title)"                                       
                                            >
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{ subItem.title }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-group>
                                    </v-list>
                                </div>
                            </v-card> 
                        </v-expand-transition>    
                   </v-overlay>    
                </v-card>
        </v-col>
        <v-dialog
            v-model="reportViewer"
            max-width="90vw"
            @click:outside="closeReport"
        >
            <v-card
               height="90vh"
               style="position:relative;" 
            >                
                <iframe 
                    ref="pdf" 
                    style="width:100%; height:100%" 
                    class="report-viewer"  
                    :src="src"
                ></iframe>              
                <v-overlay
                    absolute
                    :value="reportViewerOverlay"
                    color="white"
                    opacity="0.60"
                >
                    <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </v-overlay> 
            </v-card>
        </v-dialog> 
    </v-row>    
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
    created(){
        this.initialize()
    },
    computed: {
        
    },
    data: () => ({
        selectedReport: null,
        items: [
            { 
                title: 'Report Cards by Class', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'report-card-class',  
            },            
            { 
                title: 'Class Mark Sheets', 
                subtitle: '', 
                icon: 'mdi-printer-search' ,
                report: 'marksheet-class',
            },            
            { 
                title: 'Class Lists', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'class-list', 
            },            
        ],
        overlay: false,
        loading: false,
        expand: false,
        formClasses: [],
        academicPeriod: '',        
        academicPeriods: [],        
        reportViewer: false,
        reportViewerOverlay: false,
        src: null,
        reportSelected: null,
        displayAcademicPeriod: false,
        listGroup: false,
    }),
    methods:{
        ...mapMutations({
            //
        }),

        ...mapActions({
            getFormClassesList: 'termReports/getFormClassesList',
            getMarkSheetTerms: 'printViewReports/getMarkSheetTerms',
            getReportCardTerms: 'printViewReports/getReportCardTerms',
            getClassListYears: 'printViewReports/getClassListYears',
        }),

        async initialize(){
            this.overlay = true;
            this.loading = true;
            await this.mapFormClasses();
            this.loading = false;
            this.overlay = false;
            // console.log(this.formClasses);
        },

        async mapFormClasses () {
            try {
                const { data } = await this.getFormClassesList();
                if(data.length > 0){                    
                    let form1Classes = [];
                    let form2Classes = [];
                    let form3Classes = [];
                    let form4Classes = [];
                    let form5Classes = [];
                    let form6Classes = []; 
                    data.forEach(record => {
                        switch(record.form_level){
                            case "1":
                            form1Classes.push({
                                title: record.id
                            });
                            break;
                            case "2":
                            form2Classes.push({
                                title: record.id
                            });
                            break;
                            case "3":
                            form3Classes.push({
                                title: record.id
                            });
                            break;
                            case "4":
                            form4Classes.push({
                                title: record.id
                            });
                            break;
                            case "5":
                            form5Classes.push({
                                title: record.id
                            });
                            break;
                            case "6":
                            form6Classes.push({
                                title: record.id
                            });
                            break;
                        }                
                    });
                    this.formClasses.push({
                        title: 'Form 1',
                        action: 'mdi-school',                
                        items: form1Classes,
                        formLevel: 1
                    });
                    this.formClasses.push({
                        title: 'Form 2',
                        action: 'mdi-school',                
                        items: form2Classes,
                        formLevel: 2,
                    });
                    this.formClasses.push({
                        title: 'Form 3',
                        action: 'mdi-school',                
                        items: form3Classes,
                        formLevel: 3,
                    });
                    this.formClasses.push({
                        title: 'Form 4',
                        action: 'mdi-school',                
                        items: form4Classes,
                        formLevel: 4,
                    });
                    this.formClasses.push({
                        title: 'Form 5',
                        action: 'mdi-school',                
                        items: form5Classes,
                        formLevel: 5,
                    });
                    if(form6Classes.length > 0){
                        this.formClasses.push({
                            title: 'Form 6',
                            action: 'mdi-school',                
                            items: form6Classes,
                            formLevel: 6,
                        }); 
                    }
                                                              
                }                
            } catch (error) {
                console.log(error);
            }
        },

        filterFormClasses (formLevel = null) {
            this.listGroup = true;
            this.formClassesFilter = this.formClasses.map(
                value => {
                    value.active = false
                    return value;
                }
            );
            if(formLevel){
                this.formClassesFilter = this.formClasses.filter(value => {
                    if(value.formLevel == formLevel){
                        value.active = true;
                        return value;
                    }
                    
                })
            }            
        },

        async displayReport(report){
            // console.log(report);            
            switch(report){
                case 'report-card-class':
                    console.log('report cards');
                    this.overlay = true;
                    this.loading = true; 
                    this.reportSelected = report;
                    this.displayAcademicPeriod = true;
                    this.filterFormClasses();                   
                    this.setReportCardTerms();                    
                    break
                case 'marksheet-class':
                    console.log('class mark sheets');
                    this.overlay = true;
                    this.loading = true; 
                    this.markSheetMenu(); 
                    this.displayAcademicPeriod = true;                  
                    this.reportSelected = report;
                    this.filterFormClasses();                                       
                    break
                case 'class-list':
                    // console.log('class lists');
                    await this.setClassListYears();
                    this.reportSelected = report;
                    this.displayAcademicPeriod = true;
                    this.overlay = true;
                    this.expand= true;
                    this.filterFormClasses();
                    break
            }
            
        },

        async markSheetMenu(){
            try {
                const { data } = await this.getMarkSheetTerms();                
                // let count = 0;
                data.forEach((record) => {
                    this.academicPeriods.push({
                        text: record.academic_year + " Term " + record.term,
                        value: record.academic_term_id
                    });
                    // count = index;
                })
                
                this.academicPeriod = data[0].academic_term_id;
                // console.log(this.academicPeriod)
                this.loading = false;
                this.$nextTick(function(){
                    this.expand= true;
                })
                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        async setReportCardTerms () {
            let response = null, count = 0;
            try {
                response = await this.getReportCardTerms();                
                response.data.forEach((record,index) => {
                    this.academicPeriods.push({
                        text: record.academic_year + " Term " + record.term,
                        value: record.academic_term_id
                    });
                    count = index;                    
                })
                this.academicPeriod = response.data[count].academic_term_id;
            } catch (error) {
                if(error.response) console.log(error.reponse);
                else console.log(error);
            }

            this.loading = false;
            this.$nextTick(function(){
                this.expand= true;
            })

        },

        setSelectedClass(formClass){
            console.log(formClass);
            this.reportViewer = true;
            this.reportViewerOverlay = true;
            //const iframe = document.querySelector('#report-viewer');
            this.$nextTick( function () {
                //console.log(this.$refs.pdf);
                this.$refs.pdf.onload = () => {
                    console.log('report is loaded');
                    this.reportViewerOverlay = false;
                }
            })         
            switch(this.reportSelected){
                case 'report-card-class':                     
                    this.src = process.env.VUE_APP_API_URI + "/api/report-card/"+this.academicPeriod+"/"+formClass;
                    break;
                case 'marksheet-class':                    
                    this.src = process.env.VUE_APP_API_URI + "/api/mark-sheet/"+this.academicPeriod+"/"+formClass;
                    console.log(this.src);
                    break;
                case 'class-list':                      
                    this.src = process.env.VUE_APP_API_URI + "/api/class-list/"+formClass+"/"+this.academicPeriod;
                    break;
            }            
        },

        async setClassListYears () {
            try {
                const { data } = await this.getClassListYears();
                data.forEach(value => {
                    this.academicPeriods.push({
                        text: value.start + '-' + value.end,
                        value: value.academic_year_id

                    })
                    this.academicPeriod = value.academic_year_id;
                })
                // console.log(this.academicPeriods);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        close(){
            this.expand = false;
            this.selectedReport = null;
            setTimeout(() => { this.overlay = false}, 1000);
        },

        closeReport(){
            console.log('report closing ...');
            this.src = '';
        }
    },
    
    
}
</script>