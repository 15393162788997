<template>
    <v-dialog
        v-model="dialog"
        max-width="700px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                outlined
                text
                v-bind="attrs"
                v-on="on"
                small
            >
                {{ buttonText }}
                <v-icon
                    v-if="icon"
                    small
                    right
                >
                    {{ icon }}
                </v-icon>
            </v-btn>
        </template>
        
        <v-card ref="form" v-if="dialog">
            <v-card-title class="pb-0">
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>                        
            <v-card-text class="pb-0">
                <v-container class="py-0">
                    <v-row>
                        <v-col cols="6" class="py-0">
                            <v-container>
                                <v-row>
                                    <v-col>
                                        
                                        <v-card
                                            v-if="editedItem.picture"
                                            outlined
                                            class="mx-auto d-flex justify-center align-center"
                                            max-width="150"
                                        >
                                            <v-img
                                                :src="editedItem.picture"
                                            >
                                                <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="primary"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                </template>
                                            </v-img>
                                        </v-card>
                                        <v-card
                                            v-else
                                            outlined
                                            class="mx-auto d-flex justify-center align-center"
                                            max-width="200"
                                            height="300"
                                        >
                                            <v-card-text
                                                class="my-auto" style="text-align:center"
                                            >
                                                No Picture
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>

                                <v-row v-if="editPicture">
                                    <v-col>
                                        <v-file-input
                                            :value="pictureFile"
                                            text
                                            dense
                                            outlined
                                            @change="uploadPicture"
                                            placeholder="Add/Change Picture"
                                            prepend-icon="mdi-camera"
                                        >
                                        </v-file-input>
                                    </v-col>
                                </v-row>
                                <v-row v-if="pictureFile">
                                    <v-progress-linear
                                        v-model="progress"
                                        color="primary"
                                        height="25"
                                        reactive
                                    >
                                        <strong>{{ progress }} %</strong>
                                    </v-progress-linear>
                                </v-row>
                                <v-alert v-if="pictureUploadMessage" border="left" color="primary" dark>
                                    {{ pictureUploadMessage}}
                                </v-alert>    
                            </v-container>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-container>
                                <v-row v-if="alert.display">
                                    <v-col>
                                        <v-alert
                                            :value="alert.display"
                                            dense
                                            text
                                            :type="alert.type"
                                            transition="scale-transition"
                                        >
                                            {{ alert.text }}
                                        </v-alert>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            ref="first_name"
                                            v-model="editedItem.first_name"
                                            label="First Name"
                                            hide-details="auto"
                                            :rules="rules"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            ref="last_name"
                                            v-model="editedItem.last_name"
                                            label="Last Name"
                                            hide-details="auto"
                                            :rules="rules"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">                                            
                                        <v-menu
                                            ref="menu"
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"                   
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="date"
                                                label="Date of Birth"                                                    
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                hide-details
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                ref="picker"
                                                v-model="date"                                                
                                                min="1950-01-01"
                                                @change="saveDate"                                                
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="editedItem.birth_certificate_pin"
                                            label="Birth Certificate Pin"
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-select
                                            v-model="editedItem.gender"
                                            :items="gender"
                                            label="Gender"
                                            hide-details
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-autocomplete
                                            ref="form_class_id"
                                            v-model="editedItem.form_class_id"
                                            :items="formClasses"
                                            item-text="id"
                                            item-value="id"
                                            label="Form Class"
                                            hide-details="auto"
                                            :rules="rules"
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-container>    
            </v-card-text> 
            <v-card-actions
                class="px-4"
            >
                <span
                    v-show="saved"
                >
                    Data saved
                    <v-icon
                        small
                        color="primary"                                        
                        class="ml-3"
                    >
                        mdi-check-all
                    </v-icon>
                </span>

                <span
                    v-show="error"
                    class="red--text"
                >
                    {{ errorMessage }}
                    <v-icon
                        small
                        color="red"
                        class="ml-3"
                    >
                        mdi-alert-circle
                    </v-icon>
                </span>

                <v-spacer></v-spacer>
                <v-btn                                    
                    depressed
                    @click="close"
                    class="mr-6"
                >
                    Close
                </v-btn>
                <v-btn
                    color="primary"                                    
                    dark                                   
                    @click="save"
                >
                    Save
                </v-btn>                                
            </v-card-actions>
            <v-overlay
                :value="overlay"
                opacity="0.8"
                z-index="10"
                absolute                    
            >
                
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>                   
            </v-overlay>
        </v-card>       
    </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import UploadService from "../services/UploadFileService";
export default {
    props: {
        buttonText: String,
        formTitle: String,
        initialItem: Object,
        formClasses: Array,
        icon: String,
    },

    data: function () {
        return {
            editedItem: null,
            editPicture: true,
            pictureFile: null,
            progress: 0,
            pictureUploadMessage: null,
            menu: false,
            date: null,
            overlay: false,
            dialog: false,
            rules: [
                value => !!value || 'Required'
            ],
            gender: ['M', 'F'],
            error: false,
            formHasErrors: false,
            errorMessage: '',
            saved: false,
            alert: {
                display: false,
                type: 'success',
                text: 'Data saved',
            }
        }
    },

    watch: {
        menu (val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },

        date (val) {
            this.editedItem.date_of_birth = val;
        },

        dialog (val) {
            if(!val) {
                this.close();
                return;
            }

            this.editedItem = Object.assign({}, this.initialItem);
        },
    },

    methods: {
        ...mapMutations({
            setStudent: 'editViewStudents/setStudent',
        }),

        ...mapActions({
            postStudent: 'editViewStudents/postStudent',
            getStudents: 'editViewStudents/getCurrentStudents',
        }),

        saveDate(date){
            this.$refs.menu.save(date);
        },

        async uploadPicture (file) {
            this.progress = 0;
            this.pictureFile = file;
            if(!this.pictureFile){
                // this.pictureUploadMessage = "Please select a file!";
                return;
            }
            this.pictureUploadMessage = null;

            try {
                const { data } = await UploadService.upload(
                this.pictureFile, 
                this.editedItem.student_id,
                'picture',
                (event) => {
                    this.progress = Math.round((100 * event.loaded) / event.total);
                    } 
                )
                this.editedItem.picture = data.picture;
                const { data:dataStudents } = await this.getStudents();
                this.mapStudents(dataStudents);
                
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error);
            }
            this.pictureFile = null;
            
        },

        async save () {
            this.overlay = true
            this.setStudent(this.editedItem);
            this.formHasErrors = false;
            
            Object.keys(this.editedItem).forEach( f => {
                if(f === 'first_name' && !this.editedItem[f]){
                    this.formHasErrors = true;
                    this.$refs[f].validate(true);
                } 
                if(f === 'last_name' && !this.editedItem[f]){
                    this.formHasErrors = true;
                    this.$refs[f].validate(true);
                } 
                if(f === 'form_class_id' && !this.editedItem[f]){
                    this.formHasErrors = true;
                    this.$refs[f].validate(true);
                } 
               
            })

            if(this.formHasErrors){
                this.saved = false; 
                this.error = true;
                this.errorMessage = 'Required Fields Empty'; 
                return;
            }
            
            try {
                await this.postStudent();
                this.$emit('update-students');
                //console.log(response);
                this.alert.text = 'Data saved';
                this.alert.type = 'success';
                // this.error = false;
                // this.saved = true;  
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error);
                this.alert.text = 'Error Occured';
                this.alert.type = 'error';
                // this.errorMessage = 'Error Occured';
                // this.saved = false; 
                // this.error = true;                    
            }
            this.alert.display = true;
            this.overlay = false;
                     
        },

        close () {
            this.dialog = false;
            this.error = false;
            this.saved = false;
            this.date = null;
            this.$nextTick( () => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            Object.keys(this.editedItem).forEach(f => {
                if(f === 'first_name' || f === 'last_name' || f === 'form_class_id'){
                    this.$refs[f].reset()
                }
                
            })
            this.alert.display = false;
        },
    }
}
</script>