<template>
    <v-row>           
        <v-col>
            <v-card
                class="pa-0 mx-auto"
                width="350"                
                elevation="2"
                outlined                
            >
                <v-toolbar
                    color="primary"
                    dark
                >
                    <v-toolbar-title>Application Settings</v-toolbar-title>
                </v-toolbar>
                <v-list>                    
                    <v-list-item-group
                        color="primary"
                    >
                        <v-list-item
                            v-for="(item, i) in items"
                            :key="i"
                            @click="display(item)"
                        >
                            <v-list-item-avatar>
                                <v-icon
                                    color="primary"
                                >
                                    {{ item.icon }}
                                </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card>
        </v-col>

        <edit-term-registration
            v-if="dialog.editViewTermRegistration"
            v-bind:dialog-open="dialog.editViewTermRegistration"
        ></edit-term-registration>

        <set-term
            v-if="dialog.setTerm"
            v-bind:dialog-open="dialog.setTerm"
            v-on:dialog-close="dialogClose"
        ></set-term>

         <add-edit-subjects
            v-if="dialog.addEditSubjects"
            v-bind:dialog-open="dialog.addEditSubjects"
            v-on:dialog-close="dialogClose"
        ></add-edit-subjects>

        <promote-students
            v-if="dialog.promoteStudents"
            v-bind:dialog-open="dialog.promoteStudents"
            v-on:dialog-close="dialogClose"
            v-on:snackbar-open="snackbarOpen"
        ></promote-students>

        <post-reports
            v-if="dialog.postReports"
            v-bind:dialog-open="dialog.postReports"
            v-on:dialog-close="dialogClose"
        ></post-reports>

        <v-snackbar
            v-model="snackbar.display"
            :color="snackbar.color"
        >
            {{ snackbar.text }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar.display = false"
                >Close</v-btn>
            </template>
        </v-snackbar>
       
    </v-row>        
</template>

<script>
import SetTerm from './SettingsSetTerm';
import AddEditSubjects from './SettingsAddEditSubjects';
import PromoteStudents from './SettingsPromoteStudents.vue';
import EditTermRegistration from './SettingsEditViewTermRegistration.vue';
import PostReports from './SettingsPostReports.vue';
export default {
    components: {
        SetTerm,
        AddEditSubjects,
        PromoteStudents,
        EditTermRegistration,
        PostReports,
    },

    data: () => ({
        items: [
            // {
            //     text: 'Edit / View Term Registration', 
            //     icon:'mdi-clipboard-text',
            //     value: 'editViewTermRegistration'
            // },
            {
                text: 'Current Term', 
                icon: 'mdi-calendar-cursor',
                value: 'setTerm',
            },
            {
                text: 'Add / Edit Subjects', 
                icon: 'mdi-bookshelf',
                value: 'addEditSubjects',
            },
            {
                text: 'Promote Students', 
                icon: 'mdi-account-arrow-up',
                value: 'promoteStudents'
            },
            {
                text: 'Post Reports', 
                icon: 'mdi-upload-lock',
                value: 'postReports'
            },
        ],
        dialog:{
            editViewTermRegistration: false,
            setTerm: false,
            addEditSubjects: false,
            promoteStudents: false,
            postReports: false,
        },
        snackbar: {
            display: false,
            text: '',
            color: '',
        }
    }),
   
    methods: {
        display (item) {
            Object.keys(this.dialog).forEach(k => {
                this.dialog[k] = false;
                if(k === item.value){
                    this.dialog[k] = true;
                }
            })
        },

        snackbarOpen ({ color, text }) {
            this.snackbar.display = true;
            this.snackbar.color = color;
            this.snackbar.text = text;
        },

        dialogClose () {
            Object.keys(this.dialog).forEach(k => {
                this.dialog[k] = false;
            })
        },
    }
}
</script>