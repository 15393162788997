<template>
     <v-row>           
        <v-col>
            <v-card
                class="pa-0 mx-auto"
                width="950"
            >
                <v-card-title>
                    Edit / View Students                   
                    
                    <v-spacer></v-spacer>

                    <StudentsEdit
                        button-text="Add New Student"
                        form-title="New Student"
                        :initial-item="defaultItem"
                        :form-classes="formClasses"
                    ></StudentsEdit>
                    
                </v-card-title>

                <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :items="students"
                        fixed-header                        
                        height="45vh"
                        :calculate-widths="true" 
                        :search="search"
                        :loading="loading"
                        :custom-filter="customFilter"
                    >
                        <template v-slot:top>
                            <v-spacer></v-spacer>

                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search Student"
                                single-line
                                hide-details
                                class="mb-8"
                                clearable
                            ></v-text-field>

                            <v-dialog
                                v-model="dialogDelete"
                                max-width="430px"
                                persistent
                            >
                                <v-card
                                    v-if="confirmDelete"
                                >
                                    <v-card-title>{{ deleteStudentSelected }}</v-card-title>
                                    <v-card-text>                                        
                                        <v-select
                                            :items="statuses"
                                            v-model="status"
                                            label="Status"                                            
                                            item-text="detail"
                                            item-value="id"
                                            autofocus
                                            hide-details
                                            ref="student-status"
                                            clearable
                                            open-on-clear
                                        ></v-select>
                                    </v-card-text>
                                    <v-card-actions
                                        class="px-6"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="primary"
                                            text
                                            @click="closeDelete"
                                            outlined
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            text
                                            @click="deleteStudentConfirm"
                                            outlined
                                        >
                                            Delete
                                        </v-btn>                                        
                                    </v-card-actions>
                                </v-card>
                                <v-expand-transition>
                                    <v-card
                                        v-show="deleting"
                                        color="primary"
                                        dark
                                        class="pa-4"
                                    >
                                        <v-card-text>
                                        {{ studentDeleted }}
                                        <v-progress-linear
                                                indeterminate
                                                color="white"
                                                class="mb-0 mt-4"
                                        ></v-progress-linear>
                                        </v-card-text>
                                    </v-card>
                                </v-expand-transition>
                            </v-dialog>
                        </template>

                       

                        <template v-slot:item="props">
                            <tr>
                                <td>
                                    <v-img
                                        :src="props.item.picture"
                                        max-width="50"
                                        class="my-4"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-progress-circular
                                                    indeterminate
                                                    color="primary"
                                                ></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </td>
                                <td>{{ props.item.student_id }}</td>
                                <td>{{ props.item.name }}</td>
                                <td>{{ props.item.form_class_id }}</td>
                                <td>{{ props.item.gender }}</td>
                                <td>
                                    <StudentsEdit
                                        button-text="Edit/View"
                                        :form-title="props.item.name"
                                        :initial-item="props.item"
                                        :form-classes="formClasses"
                                        @update-students="updateStudents"
                                        icon="mdi-pencil"
                                    ></StudentsEdit>
                                    <v-btn
                                        text
                                        outlined
                                        @click="deleteStudent(props.item)"
                                        small
                                        class="ml-6"
                                    >    
                                        Delete
                                        <v-icon
                                            small                                    
                                        >
                                            mdi-delete
                                        </v-icon>
                                        
                                    </v-btn>    
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                    
                    <v-snackbar
                        v-model="snack"
                        :timeout="3000"
                        color="primary"
                    >
                        {{ snackText }}
                        <template v-slot:action="{ attrs }">
                            <v-btn
                                v-bind="attrs"
                                text
                                @click="snack = false"
                            >
                                Close
                            </v-btn>
                        </template>
                    </v-snackbar>
                </v-card-text>                
            </v-card>            
        </v-col>
     </v-row>            
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

import StudentsEdit from "./StudentsEdit.vue";
export default {
    components: {
        StudentsEdit,
    },
    created () {
        this.initialize();
    },
    data: () => ({
        headers: [
            {text: 'Picture', align: 'start', sortable: false, value: 'picture', width: '100'},
            {text: 'ID', align: 'start', sortable: false, value: 'student_id', width: '100'},
            {text: 'Name', align: 'start', sortable: false, value: 'name', width: '200'},
            {text: 'Class', align: 'start', sortable: false, value: 'form_class_id', width: '75'},
            {text: 'Gender', align: 'start', sortable: false, value: 'gender', width: '60'},
            {text: '', align: 'start', sortable: false, value: 'actions', },
        ],
        students: [],
        search: '',
        loading: false,
        snack: false,
        snackText: '',
        options: {
            itemsPerPage: -1,
        },
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
            first_name: '',
            last_name: '',
            date_of_birth: '',
            birth_certificate_pin: '',
            gender: '',
            form_class_id: '',
            student_id: '',
            picture: '',
        },
        deletedItem: {
            first_name: '',
            last_name: '',
            date_of_birth: '',
            birth_certificate_pin: '',
            gender: '',
            form_class_id: ''
        },
        defaultItem: {
            first_name: '',
            last_name: '',
            date_of_birth: '',
            birth_certificate_pin: '',
            gender: '',
            form_class_id: '',
            student_id: '',
            picture: '',
        },
        formClasses: [],
        expanded: [],
        singleExpand: false,
        overlay: false,
        statuses: [],
        status: 3,
        deleteStudentSelected: null,
        confirmDelete: true,
        deleting: false,
        buttonText: null,
        formTitle: null,
    }),

    computed: {
        studentDeleted () {
            return 'Deleting ' + this.deletedItem.first_name + ' ' + this.deletedItem.last_name;
        }
    },

    watch: {
       
        dialogDelete (val) {
            val || this.closeDelete()
        },
    },

    methods: {
        ...mapActions({
            getStudents: 'editViewStudents/getCurrentStudents',
            getFormClasses: 'termReports/getFormClassesList',
            postStudent: 'editViewStudents/postStudent',
            getStudentStatus: 'editViewStudents/getStudentStatus',
            delete: 'editViewStudents/deleteStudent',
        }),

        ...mapMutations({
            setStudent: 'editViewStudents/setStudent',
        }),

        async initialize(){
            this.loading = true;
            try {
                const promiseStudents = this.getStudents();
                const promiseFormClasses = this.getFormClasses();
                const promiseStudentStatus = this.getStudentStatus();

                const [
                    { data: dataStudents },
                    { data: dataFormClasses },
                    { data: dataStudentStatus }
                ] = await Promise.all([
                    promiseStudents,
                    promiseFormClasses,
                    promiseStudentStatus,
                ])

                this.mapStudents(dataStudents);
                
                this.formClasses = dataFormClasses;
                
                dataStudentStatus.forEach(record => {
                    if(record.id > 2){
                        this.statuses.push(record);
                    }
                })
                
            } catch (error) {
                if(error.response) console.log(error.response)
                else console.log(error);
                console.log(error.response);
            }
        },

        async updateStudents () {
            const { data } = await this.getStudents();
            this.mapStudents(data);

        },

        mapStudents (data) {
            this.students = data.map(value => {
                value.name = value.last_name + ', ' + value.first_name;
                if(!value.picture){
                    value.picture = require("../assets/icons/student2.svg")
                    value.no_picture = true
                }
                return value;
            });
            this.loading = false;
        },

        async editStudent(item){
            this.editedIndex = this.students.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.date = item.date_of_birth;
            this.dialog = true;
            
        },

        deleteStudent(item){
            //console.log(item);           
            this.deletedItem = Object.assign({}, item);
            this.deleteStudentSelected = item.first_name + ' ' + item.last_name;
            this.dialogDelete = true;
            this.confirmDelete = true;
            //console.log(this.$refs);
            this.$nextTick(() => {
                console.log(this.$refs['student-status'])
                //this.$refs['student-status'].isActive = true;
            })
           
        },      
        
        closeDelete(){
            this.dialogDelete = false;
            this.status = 3;            
        },

        async deleteStudentConfirm(){
            console.log('deleting...');
            this.confirmDelete = false;
            setTimeout(() => {
                this.deleting = true;
            });
            
            //console.log(this.deletedItem);
            this.setStudent({
                student_id: this.deletedItem.student_id,
                student_status_id: this.status
            });
            try {
                let response = await this.delete();
                console.log(response);
                const { data } = await this.getStudents();
                this.mapStudents(data);                
                this.deleting = false;                               
            } catch (error) {
                console.log(error);
            }
            setTimeout(() =>{
                this.dialogDelete = false;                 
            })
            setTimeout(() => {
                this.snack = true;
                this.snackText = this.deletedItem.first_name + ' ' + this.deletedItem.last_name + ' deleted.'
            })           
            
        },

        customFilter (value, search) {
            return value != null &&
            search != null &&
            value.toString().toLocaleUpperCase().indexOf(search.toString().toLocaleUpperCase()) !== -1 &&
            value.toString().indexOf('image') == -1
        },
    }
}
</script>