<template>
    <v-expand-transition>
         <v-card
            class="mx-auto"
            width="400"            
            v-show="expand"
            light                        
        >    
            <v-card-title
                class="primary white--text"                
            >
                Select Teacher
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="teachers"
                    :search="search"
                    hide-default-footer
                    hide-default-header
                    disable-pagination
                    :loading="loading"
                    height="40vh"
                >
                    <template v-slot:top>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search Teacher"
                            hide-details
                            clearable
                            class="mb-4"
                        ></v-text-field>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn
                            color="primary"
                            @click="selectTeacher(item)"
                            small
                        >
                            Select
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
         </v-card>    
    </v-expand-transition>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    created: function () {
        this.initialize();
    },

    computed: {
        ...mapGetters({
            employeeId: 'auth/getEmployeeId',
        })
    },

    data: function () {
        return {
            expand: false,
            headers:[
                { text: '', value: 'name'},
                { 
                    text: '', 
                    value: 'actions',
                    width: '100',
                    sortable: false,
                }
            ],
            teachers: [],
            search: '',
            itemsPerPage: 100,
            loading: false,
        }
    },

    methods: {
        async initialize () {
            try {
                this.loading = true;
                const { data } = await this.getEmployees();
                let currentEmployee;
                data.forEach(record => {
                    record.name = record.last_name + ' ' + record.first_name;
                    if(record.id != this.employeeId){
                        this.teachers.push(record);
                    }
                    else{
                        currentEmployee = record;
                    }                    
                })
                this.teachers.unshift(currentEmployee);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error); 
            }
            this.loading = false;
            this.$emit('stop-loading');
            this.$nextTick(() => {
                this.expand = true;
            })            
        },

        ...mapMutations({
            setSelectedEmployee: 'markSheet/setSelectedEmployee'
        }),

        ...mapActions({
            getEmployees: 'employees/getEmployees',
        }),

        selectTeacher (item) {
            this.setSelectedEmployee(item.id);
            this.expand = false;
            this.$nextTick(() => {
                this.$emit('display-lessons');
            })
        }
    }
}
</script>