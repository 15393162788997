<template>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        persistent
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                outlined
                text
                v-bind="attrs"
                v-on="on"
            >
                Add New Employee
            </v-btn>            
        </template>

        <v-card 
            v-show="addNewEmployee"
            ref="form"
        >
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>                        
            <v-card-text>
                <v-container>
                    <v-row
                        v-for="field in fields"
                        :key="field.ref" 
                    >
                        <v-col 
                            cols="12"
                            v-if="field.type == 'text' && field.required"
                        >
                            <v-text-field
                                :ref="field.ref"
                                v-model="newEmployee[field.model]"
                                :label="field.label"
                                hide-details="auto"
                                :rules="field.rules"
                                clearable
                            ></v-text-field>
                        </v-col>   
                        
                        <v-col 
                            cols="12"
                            v-else-if="field.type == 'text'"
                        >
                            <v-text-field
                                :ref="field.ref"
                                v-model="newEmployee[field.model]"
                                :label="field.label"
                                hide-details="auto"
                                clearable
                            ></v-text-field>
                        </v-col>

                        <v-col 
                            cols="12"
                            v-else-if="field.type == 'select'"
                        >
                            <v-select
                                v-model="newEmployee[field.model]"
                                :items="field.items"
                                :label="field.label"
                                hide-details
                                clearable
                            ></v-select>
                        </v-col>

                    </v-row> 

                    <v-row>
                        <v-col 
                            cols="12"                           
                        >                                            
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"                   
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="date"
                                    label="Date of Birth (optional)"                                                    
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    hide-details
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    ref="picker"
                                    v-model="date"                                                
                                    min="1950-01-01"
                                    @change="saveDate"                                                
                                ></v-date-picker>
                            </v-menu>
                        </v-col>    
                    </v-row>                       
                </v-container> 
            </v-card-text> 

            <v-card-actions
                class="px-4"
            >  
                <v-spacer></v-spacer>
                <v-btn                                    
                    text
                    @click="close"
                >
                    Close
                </v-btn>
                <v-btn
                    color="primary"                                    
                    text
                    outlined                              
                    @click="save"
                >
                    Save
                </v-btn>                                
            </v-card-actions>            
        </v-card>

        <v-expand-transition>
            <v-card
                v-show="adding"
                color="primary"
                dark
                class="pa-4"
            >
                <v-card-text>
                    <span class="mr-2">Adding</span> {{ newEmployeeName }}
                <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0 mt-4"
                ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-expand-transition> 
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    created () {
        this.intialize();
    },
    data: function () {
        return {
            fields: [
                        
            ],        
            rules: {
                required: value => !!value || 'Field cannot be blank',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },
            newEmployee: {},
            menu: false,
            date: null,
            dialog: false,
            adding: false,
            addNewEmployee: true,                
            
        }
    },

    watch: {
        dialog (val) {
            val || this.close()
        },
        menu (val) {            
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))            
        },
        date (val) {
            this.newEmployee['date_of_birth'] = val;
        }
    },
    computed: {
        ...mapGetters({            
            editedIndex: 'employees/getEditedIndex',
        }),
        formTitle () {
            return this.editedIndex === -1 ? 'New Employee' : 'Edit Employee'
        },
        newEmployeeName () {
            return this.newEmployee.first_name + ' ' + this.newEmployee.last_name;
        }
    },
    methods: {
        ...mapActions({
            postEmployee: 'employees/postEmployee',  
        }),
        ...mapMutations({
            setNewEmployee: 'employees/setEditedEmployee',
            setSnack: 'employees/setSnack',
            setSnackText: 'employees/setSnackText',
            setSnackColor: 'employees/setSnackColor',
        }),

        intialize(){
            this.fields  = [
                {ref: 'teacher_num', model: 'teacher_num', label: 'Teacher Num (optional)', type: 'text'},
                {ref: 'first_name', model: 'first_name', label: 'First Name', type: 'text', required: true, rules: [this.rules.required]},
                {ref: 'last_name', model: 'last_name', label: 'Last Name', type: 'text', required: true,  rules: [this.rules.required]},
                {ref: 'gender', model: 'gender', label: 'Gender (optional)', type: 'select', items: ['M', 'F']},
                {ref: 'email_address', model: 'email_address', label: 'Email Address', type: 'text', required: true, rules: [this.rules.required, this.rules.email]},
                {ref: 'date_of_birth', model: 'date_of_birth', label: 'Date of Birth', type: 'date'},  
            ]
        },
        saveDate(date){
            this.$refs.menu.save(date);
        },
        close(){
            this.dialog = false;
            for(const f in this.$refs){
                    if(!this.$refs[f][0]) continue;
                    this.$refs[f][0].reset();
                }
        },
        async save(){
            if(
                !this.newEmployee.first_name || 
                !this.newEmployee.last_name ||
                !this.newEmployee.email_address
            ){
                for(const f in this.$refs){
                    if(!this.$refs[f][0]) continue;
                    this.$refs[f][0].validate(true);
                }
                return;
            }

            this.addNewEmployee = false;
            this.adding = true;
            console.log('saving...');
            this.newEmployee['id'] = "";
            console.log(this.newEmployee);
            this.setNewEmployee(this.newEmployee);
            try {
                const response = await this.postEmployee();
                console.log(response);
                this.adding = false;
                setTimeout(() => {
                    this.dialog = false                        
                    this.setSnack(true);
                    this.setSnackColor('primary');
                    this.setSnackText(this.newEmployee.first_name + ' ' + this.newEmployee.last_name + ' added.');
                    this.newEmployee = {};  
                });

                setTimeout(() => {
                    this.addNewEmployee = true; 
                }, 1000);
                this.$emit('reload-employees');
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error);
                this.adding = false;
                setTimeout(() => {
                    this.dialog = false
                    this.addNewEmployee = true;
                    this.setSnack(true);
                    this.setSnackText(error.response.data.message);
                    this.setSnackColor('red');
                    this.newEmployee = {};  
                });
            }
                             
        }
    }
}
</script>